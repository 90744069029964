<template>
    <section>
            
        <div class="container">

        <div class="notification mt-12">
            <div class="columns is-centered">

                <div class="column is-4">
                    
                    <!-- El input envía los datos ingresados a la Data de Vue Js y luego dentro del "submit prevent"
                    igualo esos datos a los argumentos Email y Password que le paso a la función ingresoUsuario --> 
                    <form @submit.prevent="recoverPassword({email:email})">

                            <p class="colorPrimario mb-1 has-text-centered"> Recuperar contraseña </p>

                            <!-- Email -->
                            <b-field  label="Email"  :label-position="labelPosition">
                                <b-input type="email"
                                    placeholder="Ingresa un email"
                                    v-model="email"
                                    maxlength="100"
                                    :has-counter="false"
                                  >
                                </b-input>
                            </b-field>

                            <div class="mb-2 is-block"></div>

                            <p class="subtitle is-7 mt-1 colorSuccess">{{message}}</p>
                            <p class="subtitle is-7 mt-1 colorSoftRed" v-if="error === 'auth/user-not-found'">
                            Este email no está registrado
                            </p>
                            <!-- Boton -->
                            <div class="column has-text-centered marginBoton p-0">
                                      <b-button tag="input"
                                      native-type="submit"
                                      class="bgColorPrimary has-text-centered colorWhite w100"
                                      value="Recuperar"
                                      ></b-button>
                            </div>
                            
                                
                    </form>
                    
                  
                            </div>
                            
                </div>

              </div>         
            </div>
    
    </section>
</template>

<script>

import { mapActions, mapState, mapMutations } from "vuex"
import { required, minLength, email } from 'vuelidate/lib/validators'
import router from "@/router"

// Importo los metodos del archivo firebase
import { firebase, auth, db } from "@/firebase"

export default {
    
    name: 'CmpRecoverPassword',

    data() {
        return {
            email:'',
            labelPosition: 'on-border',
        }
    },

    methods: {
        ...mapActions('sessions',['recoverPassword']),
     },

     computed: {
         ...mapState('generalFunctions',['message','error'])
     }
}
</script>

<style scoped>

/* Loader */
/* Loader */

.loaderCentrado {
    display:block !important;
    margin:auto !important;
    width:60px !important;
    margin-top:140px!important;
}

.textoCargando {
       color:#ff7850!important;
       font-weight:600!important; 
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}


/* Width */
/* Width */

.w100 {
    width:100%!important;
}

.maxW300 {
    max-width:300px!important;
}

.maxW200 {
    max-width:200px!important;
}

.maxW100 {
    max-width:100px!important;
}

.maxW50 {
    max-width:50px!important;
}



/* Bordes */
/* Bordes */

.borderPrimary {
    border-color:#8752aa!important;
}

.borderSecondary {
    border-color:#ff7850!important;
}

.borderTertiary {
    border-color:#ffc627!important;
}


/* Botoness */
/* Botones */


.button:hover, .button.is-hovered {
    border-color: transparent!important;
    color: #363636;
}

.marginBoton {
    margin-left:0px !important;
    margin-right: 0px !important;
    margin-top:0px !important;
    margin-bottom:10px !important;
}

/* Margins */
/* Margins */

.mt-0 {
    margin-top:0px!important;
}

.mt-1 {
    margin-top:10px!important;
}

.mb-1 {
    margin-bottom:10px!important;
}


.mt-2 {
    margin-top:20px!important;
}

.mb-2 {
    margin-bottom:20px!important;
}


.mt-12 {
    margin-top:80px!important;
}

.mb-12 {
    margin-bottom:100px!important;
}
/* Padding */
/* Padding */

.p-0 {
    padding:0px!important;
}

.p-1 {
    padding:20px!important;
}

.p-2 {
    padding:20px!important;
}

.p-3 {
    padding:30px!important;
}

/* Colors bg */
/* Colors bg */

.bgColorPrimary {
    background-color:#8752aa!important;
}

.bgColorSecondary {
    background-color:#ff7850!important;
}

.bgColorTertiary {
    background-color:#ffc627!important;
}

.bgColorSoftRed {
    background-color:#f64444!important;
}

.bgColorSoftBlue {
    background-color:#3d40fd!important;
}

/* Input */
/* Input */

.control .help.counter {
    float: right;
    margin-left: 0.5em;
    display: none!important;
}

</style>