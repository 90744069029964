<template>
    <div>
        <CmpRecoverPassword />
    </div>
</template>

<script>
import CmpRecoverPassword from '@/components/login/RecoverPassword.vue'

export default {
    name: 'VRecoverPassword',
    components: {
        CmpRecoverPassword,
    }
}
</script>
